// Modules exported here will be included in bundle.js

import Typed from 'typed.js';

//Placeholder class for typed animations
class Placeholder {
  constructor(input) {
    this.input = input;
  }

  init(strings = []) {
    let overtyped = new Typed(this.input, {
        strings,
        showCursor: true,
        cursorChar: '|',
        typeSpeed: 45,
        backSpeed: 20,
        smartBackspace: true,
        loop: true,
        startDelay: 500,
        backDelay: 2000,
        attr: 'placeholder',
    });

    this.input.addEventListener('focus', () => {
        overtyped.stop();
        overtyped.reset();
    });
    this.input.addEventListener('blur', () => {
        overtyped.start();
    });
    }
}

export {
  Placeholder
}
